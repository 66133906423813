<template>
  <v-card
    v-if="entity && !loading"
    id="manifest-edit"
    class="entity-edit"
  >

    <v-card-title>{{entity.Summary}}</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-alert
        v-if="serverErrorMessage"
        type="error"
      >
        {{ serverErrorMessage }}
      </v-alert>
    </v-card-text>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>
          <v-btn
            color="primary"
            class="me-3"
            @click="save"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn
            color="secondary"
            class="me-3"
            @click="Back"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            color="error"
            class="me-3"
                 :loading="loading"
            @click="dispatch"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiCubeSend }}
            </v-icon>
            <span>Dispatch</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        style="background-color:#f1f1f1"
      >
        <div class="pa-5">
          <h4 class="mb-5">Ready To Manifest</h4>
          <v-text-field
            v-model="q"
            single-line
            dense
            outlined
            hide-details
            clearable
            style="background-color:#fff"
            class="mb-5"
            placeholder="Search"
          ></v-text-field>
          <v-alert
            type="info"
            v-if="filteredReady.length == 0 && entity.Ready.length > 0"
          >
            No orders found with this search term.
          </v-alert>
          <v-alert
            type="info"
            v-if="entity.Ready.length == 0"
          >
            No orders are ready to be manifested for this destination.
          </v-alert>
          <v-card
            tile
            class="mb-2"
            v-for="(order, index) in filteredReady"
            :key="'r'+order.Id"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{order.Name}}

                    <template v-if="order.CustomsErrors && order.CustomsErrors.length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <div v-bind="attrs" v-on="on">
                          <router-link class="text-decoration-none" target="_blank"
                        :to="{ name: 'order-view', params: { id: order.Id } }">
                        <v-icon small color="error">{{ icons.mdiAlertBox }}</v-icon>
                        </router-link>
                        </div>
                      </template>
                      <span>{{ order.CustomsErrors.join(", ") }}</span>
                    </v-tooltip>
                    </template>
                    <template v-else>
                        <router-link v-bind="attrs" v-on="on" class="text-decoration-none" target="_blank"
                      :to="{ name: 'order-view', params: { id: order.Id } }">
                      <v-icon small color="secondary">{{ icons.mdiOpenInNew }}</v-icon>
                      </router-link>
                    </template>

                </v-list-item-title>
                <v-list-item-subtitle>
                  {{order.Tracking}}
                </v-list-item-subtitle>
                <div
                  class="mt-1 mb-1"
                  style="height:1px;width:100%; background-color:#e1e1e1"
                ></div>
                <v-list-item-subtitle
                  v-for="(item, itemIndex) in order.Items"
                  :key="'i'+item.ItemId"
                  style="font-size:80%"
                >
                  Item {{itemIndex + 1}}: {{item.Length}}L x {{item.Width}}W x {{item.Height}}H
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="true || !order.CustomsErrors || order.CustomsErrors.length == 0"
                  icon
                  @click="addToManifest(order, index)"
                >
                  <v-icon color="grey">{{ icons.mdiArrowRightBold}}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        style="background-color:#e1e1e1"
      >
        <div class="pa-5">
          <h4 class="mb-5">Manifest</h4>
          <v-card
            tile
            class="mb-2"
            v-for="(order,index) in entity.Orders"
            :key="order.Id"
          >
            <v-list-item>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="removeFromManifest(order, index)"
                >
                  <v-icon color="grey">{{ icons.mdiArrowLeftBold}}</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{order.Name}}

                  <template v-if="order.CustomsErrors && order.CustomsErrors.length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">

                        <div v-bind="attrs" v-on="on">
                          <router-link class="text-decoration-none" target="_blank"
                        :to="{ name: 'order-view', params: { id: order.Id } }">
                        <v-icon small color="error">{{ icons.mdiAlertBox }}</v-icon>
                        </router-link>
                        </div>
                      </template>
                      <span>{{ order.CustomsErrors.join(", ") }}</span>
                    </v-tooltip>
                    </template>
                    <template v-else>
                        <router-link v-bind="attrs" v-on="on" class="text-decoration-none" target="_blank"
                      :to="{ name: 'order-view', params: { id: order.Id } }">
                      <v-icon small color="secondary">{{ icons.mdiOpenInNew }}</v-icon>
                      </router-link>
                    </template>

                </v-list-item-title>
                <v-list-item-subtitle>
                  {{order.Tracking}}
                </v-list-item-subtitle>
                <div
                  class="mt-1 mb-1"
                  style="height:1px;width:100%; background-color:#e1e1e1"
                ></div>
                <v-list-item-subtitle
                  v-for="(item, itemIndex) in order.Items"
                  :key="'i'+item.ItemId"
                  style="font-size:80%"
                >
                  Item {{itemIndex + 1}}: {{item.Length}}L x {{item.Width}}W x {{item.Height}}H
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import { ref, watch, computed, expose, getCurrentInstance } from '@vue/composition-api'
  import {
    mdiContentSaveOutline,
    mdiArrowRightBold,
    mdiArrowLeftBold,
    mdiCubeSend,
    mdiChevronLeft,
    mdiOpenInNew,
    mdiAlertBox
  } from '@mdi/js'

  export default {
    setup() {
      const vm = getCurrentInstance().proxy
      const { route, router } = useRouter()

      store.state.app.Manifest = null // otherwise you see old details before new ones loaded. TODO consider rewriting this

      const entity = computed(() => {
        return store.state.app.Manifest
      })

      const loading = ref(false)
      const serverErrors = ref([])
      const serverErrorMessage = ref(null)

      const q = ref('')

      const filteredReady = computed(() => {
        if (!vm.q) {
          return vm.entity.Ready
        } else {
          return vm.entity.Ready.filter(function (order) {
            if (order.Name.toLowerCase().includes(vm.q.toLowerCase())) {
              return true
            } else if (order.Tracking) {
              if (order.Tracking.toLowerCase().includes(vm.q.toLowerCase())) {
                return true
              }
            }
          })
        }
      })

      const fetchData = id => {
        store
          .dispatch('app/fetchManifest', {
            id: id,
          })
          .then(response => {
            loading.value = false
          })
          .catch(error => {
            loading.value = false
            console.log(error)
            router.push({ name: 'manifest-list', params: { id: route.value.params.id } })
          })
      }

      fetchData(route.value.params.id)

      const addToManifest = (order, index) => {
        vm.entity.Ready.splice(index, 1)
        vm.entity.Orders.unshift(order)
      }

      const removeFromManifest = (order, index) => {
        vm.entity.Orders.splice(index, 1)
        vm.entity.Ready.unshift(order)
      }

      const save = () => {
        vm.serverErrorMessage = null
        vm.serverErrors = []
        vm.loading = true
        vm.$http
          .put(`${store.state.apiUrl}/api/manifests/${vm.entity.Id}`, vm.entity)
          .then(response => {
            store.commit('app/SNACKBAR_ADD', { color: "primary", message: "Saved." });
          })
          .catch(ex => {
            var e = ex.response.data
            if (e.error) {
              if (e.message) vm.serverErrorMessage = e.message
              if (e.errors) vm.serverErrors = e.errors
            } else {
              console.log(e.error);
            }
          })
          .finally(() => {
            vm.loading = false
          })
      }

      const dispatch = () => {
        vm.serverErrorMessage = null
        vm.serverErrors = []
        vm.loading = true
        vm.$http
          .post(`${store.state.apiUrl}/api/manifests/${vm.entity.Id}/dispatch`, vm.entity)
          .then(response => {
            if (response.data && response.data.error) {
              store.commit('app/SNACKBAR_ADD', { color: "error", message: response.data.message });
            } else {
              store.commit('app/SNACKBAR_ADD', { color: "success", message: "Manifest Dispatched." });
              router.push({ name: 'manifest-list' });
            }
          })
          .catch(ex => {
            var e = ex.response.data
            if (e.error) {
              if (e.message) vm.serverErrorMessage = e.message
              if (e.errors) vm.serverErrors = e.errors
            } else {
              console.log(e.error);
            }
          })
          .finally(() => {
            vm.loading = false
          })
      }

      const Back = () => {
        router.go(-1);
      };

      return {
        Back,
        loading,
        entity,
        addToManifest,
        removeFromManifest,
        q,
        filteredReady,
        save,
        dispatch,
        icons: {
          mdiContentSaveOutline,
          mdiChevronLeft,
          mdiArrowRightBold,
          mdiArrowLeftBold,
          mdiCubeSend,
          mdiChevronLeft,
          mdiOpenInNew,
          mdiAlertBox
        },
        serverErrorMessage,
        serverErrors,
      }
    },
  }
</script>
<style lang="scss" scoped>
.entity-edit {
  max-width: 100%;
}
.entity-edit::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-edit::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-edit::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-edit::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-edit::v-deep .v-input--selection-controls {
  margin: 10px;
}
.entity-edit::v-deep .v-list-item__title {
  display: flex;
  align-items: flex-start;

  a {
    display: flex;
    align-items: center;
    margin: 1px 0 0 3px;
    opacity: 0.5;
  }
}
</style>
